@import "~bootstrap/scss/bootstrap";
@import "./sass/variables";

body {
    color: $default-text-color;
}

.btn-primary {
    background-color: $primary_1;
    &:hover, &:active, &:focus {
        background-color: $primary_1;
    }
}

a, .btn-link {
    text-decoration: none;
}