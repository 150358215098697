.accordion--title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-left: 15px;
    border-radius: 10;

    button {
        color: #1C3664;
        font-weight: bold;
        font-size: 18;
        max-width: 300
    }
}

.accordion--body-container {
    padding: 10px;
}