.contact-container {
    dl {
        margin-bottom: 0;
    }
    dt {
        margin-right: 10px;
    }
    dd, dt {
        display: inline-block;
    }
}

.table > :not(:first-child) {
    border-top: 2px solid #dee2e6;
}

.responsiveTable tbody tr {
    border-color: #dee2e6;
}