.group-list--item {

    text-align: left;
    button {
        width: 100%;
    }

    h5, h6 {
        text-align: left;
    }

    h5 {
        font-size: 18px;
        color: black;
    }

    h6 {
        font-size: 14px;
        color: #666666;
    }

    
}

.group-list--image-container {
    display: flex;
}

svg.group-list--image {
    display: inline-block;
    max-width: 100%;
    height: 40px;
    object-fit: cover;
    margin-right: 20px;
}