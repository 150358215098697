@import "../sass/variables.scss";

.main-menu-items {
    a {
        color: $default-text-color;
        text-decoration: none;
    }
    svg {
        color: $default-text-color;
    }
}