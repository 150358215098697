@import "../sass/variables";

.menu-collapse-button {
    font-size: 30px;
}

.offcanvas-header img{
    height: 40px;
}

.navbar {
    img {
        height: 40px;
    }
    &.color {
        background-color: $primary_1;
        & .btn-link {
            background-color: $primary_1;
        }        
    }

    &, & a, & .btn-link {
        color: white;
    }

    .btn-link {
        filter: brightness(0.85);
    }
}