@import "../sass/variables.scss";

.notice-list-item {
    color: $default-text-color;

    &:hover {
        background-color: $hover_color;
    }
}

.notice-list-item--link {
    color: $default-text-color;
    text-decoration: none;

    &:hover {
        color: $default-text-color;
        text-decoration: none;
    }

    h5 {
        font-weight: 500;
        color: black;
    }
}