@import "../sass/variables";

.field--people-header {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.field--label {
    font-weight: bold;
    font-size: 18px;
}

.field--text {
    color: $default-text-color;
    font-size: 18px;
    text-align: center;
}
.field--link {
    color: $default-text-color;
    font-size: 18px;
    font-weight: "bold";

    svg {
        margin-right: 10px;
    }
}
.field--html-text {
    p, li {
        color: $default-text-color;
        font-size: 18px;
        text-align: justify;
    }

    ul {
        margin-top: 20px;
    }

    img {
        width: 100% !important;
        height: auto;
    }
}